:root {
  --PageMyLibrary-grid-width: 360px;
  --PageMyLibrary-grid-cover-width: 500px;
  --grid-column-gap: 60px;
  --grid-row-gap: 20px;
}

@media (max-width: 1023px) {
  :root {
    --PageMyLibrary-grid-width: 290px;
    --PageMyLibrary-grid-cover-width: 500px;
    --grid-column-gap: 60px;
    --grid-row-gap: 20px;
  }
}

@media (max-width: 768px) {
  :root {
    --PageMyLibrary-grid-width: 410px;
    --PageMyLibrary-grid-cover-width: 300px;
    --grid-column-gap: 30px;
    --grid-row-gap: 10px;
  }
  /* .gridCover{
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
}
@media (max-width: 320px) {
  :root {
    --PageMyLibrary-grid-width: 280px;
    --PageMyLibrary-grid-cover-width: 300px;
    --grid-column-gap: 30px;
    --grid-row-gap: 10px;
  }
  ._3th9OUz-RJR1N-XtJoiJot {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
._1v6GxTRlKiNJk-WJXs7fWI {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageMyLibrary-grid-width), 1fr)
  );
  grid-column-gap: 20px;
  grid-row-gap: 40px;
}
._3th9OUz-RJR1N-XtJoiJot {
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--PageMyLibrary-grid-cover-width), 1fr)
  );
  grid-column-gap: var(--grid-column-gap);
  grid-row-gap: var(--grid-row-gap);
}
._3qLF6u5OJDCRr0GBrlJhLd .react-tabs__tab {
  padding: 0 10px 20px 10px;
  border: none;
  background-color: transparent;
  color: var(--color-content-default3);
  font-weight: bold;
}

._3qLF6u5OJDCRr0GBrlJhLd .react-tabs__tab--selected {
  border-bottom: 4px solid var(--color-content-highlight-hover);
  background-color: transparent;
  color: var(--color-content-default1);
}

._3qLF6u5OJDCRr0GBrlJhLd .react-tabs__tab-list {
  margin: 0 0 20px 0;
}
._34VJ1rOs4WxgLinx0eQDmr {
  text-decoration: none;
  margin: 0;
  text-overflow: ellipsis;
  font-size: var(--title-fontSize);
  font-weight: bold;
  color: var(--color-thumbnail-primary1);
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 40px 0 40px 0;
}
