._20PHhKsbpFzoS1QQ0J5JU1 {
  display: inline-flex;
  align-items: flex-start;
  width: calc(100% - 20px);
  /* 10px of content and 10px of image */
  margin: 0 0 10px 0;
  padding: 10px;
  background-color: var(--color-content-overlay1);
  color: var(--color-content-default1);
}

._3uuj5DSg9-OA6yPISBlDmo {
  border: 1px solid var(--color-highlight-hover1);
}

._20PHhKsbpFzoS1QQ0J5JU1:hover {
  background-color: var(--color-content-overlay2);
}

._1qVeBQGHj8pjr2-mHOrb4- {
  font-weight: normal;
}

._2VHs_pjha5gXCKKL-hnYil {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 10px 0 0;
}
._3VAmxoLktBQvNeBg_TlqwF {
  display: flex;
  flex-direction: row;
}

._9LHzImBGnrwa8rNtlNg2v {
  margin: 0 10px 0 0;
}

._1zXZFmAB0Bs6OQwDP44ahB {
  display: flex;
  flex-flow: column;
  flex: 1;
  margin: 0 10px 0 0;
}

/* .mainInfo {
  flex: 1;
  justify-content: center;
  width: 100%;
}

.statusInfo {
  flex: 1;
  justify-content: center;
  width: 25%;
} */

._2iNSHJfvh-zgy5HQgTxFzX {
  justify-content: center;
  min-width: 202px;
}

._1fjZnR95hlg4h-Gjw7-oxi {
  justify-content: center;
  align-items: end;
}

._17XVCfFN6jaC6Atjx5Pd48 {
  margin: 0 0 5px 0;
  font-size: var(--fontSize-l);
  font-weight: bold;
}

._1OeWGTqyV7hm4ijTGrXPTU {
  color: var(--color-content-highlight-hover);
  font-weight: bold;
}

._5b5XBeIgAPLyt5yDj1q7n {
}

.VQEFCY0QGU_1DhrgJ0anO {
  margin: 10px 0 0 0;
  font-weight: normal;
  font-size: var(--fontSize-s);
  cursor: pointer;
}

._1_IQmSPvNVuXSIqeMLEweG {
  margin: 0 0 10px 0;
}

.g-Ne2d13YjdMtNPAp_Zk6 {
  color: var(--color-content-highlight-hover);
}

._18J40aJt374IoGupkmKQW7 {
  color: var(--color-content-error);
}

._39OVC24kSWP_xf8Q4LUqHT {
  margin: 5px 0 5px 0;
  text-transform: none;
  font-weight: normal;
  min-height: fit-content;
  /*fix when text is too long (text comes out of the button) */
}

.Collapsible__trigger {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
  font-weight: 600;
  font-size: var(--fontSize-s);
  cursor: pointer;
}

.Collapsible__trigger ._1l7Ni9fJPH3xLJ6lbnDylT {
  width: 20px;
  height: 20px;
  transform: rotate(-270deg);
}

.Collapsible__trigger ._1l7Ni9fJPH3xLJ6lbnDylT path {
  stroke: var(--color-content-default1);
}

.Collapsible__trigger.is-open ._1l7Ni9fJPH3xLJ6lbnDylT {
  transform: rotate(270deg);
}

._2C-4BnEeZJU5oEzfDvD50N {
  --Checkbox-width: 22px;
  --Checkbox-height: 22px;
}

.KjjhopcY0AjA3CTr1sdLC {
  cursor: pointer;
}

._1vcjrxpG_FVGCQOZnIfGvI {
  color: var(--color-content-highlight-hover);
  font-weight: bold;
}
@media (min-width: 768px) and (max-width: 1024px) {
  ._1zXZFmAB0Bs6OQwDP44ahB {
    flex: 1;
    margin: 0;
  }
  ._3VAmxoLktBQvNeBg_TlqwF {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 0;
  }
  ._2CzeDxjWMdQVFNt_shxOfb {
    font-size: 14px;
    margin: 11px 0px;
  }
  ._1_IQmSPvNVuXSIqeMLEweG {
    margin: 0;
  }
}
@media (max-width: 767px) {
  ._20PHhKsbpFzoS1QQ0J5JU1 {
    flex-flow: column;
    padding: 0;
    width: 100%;
  }

  ._9LHzImBGnrwa8rNtlNg2v {
    width: 100%;
    height: auto;
    margin: 0;
  }

  ._1WtrLz-xYO1FzB0SPCbW4g {
    min-height: 160px;
  }

  ._2VHs_pjha5gXCKKL-hnYil {
    flex-flow: column;
    margin: 10px 0 10px 0;
  }

  ._3VAmxoLktBQvNeBg_TlqwF {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  ._1zXZFmAB0Bs6OQwDP44ahB {
    width: 100%;
    margin: 0;
  }

  ._17XVCfFN6jaC6Atjx5Pd48 {
    font-size: var(--fontSize-m);
  }

  ._1MEoRBu2vfzN8cwhzXxluT,
  .zu1qPflJbEYlHPHxMtJ5u {
    padding-left: 20px;
  }

  .zu1qPflJbEYlHPHxMtJ5u {
    margin: 40px 0 0 0;
  }

  ._5b5XBeIgAPLyt5yDj1q7n {
    margin-right: 10px;
  }

  ._2iNSHJfvh-zgy5HQgTxFzX {
    display: flex;
    align-items: center;
  }

  ._39OVC24kSWP_xf8Q4LUqHT {
    font-size: var(--fontSize-s);
  }

  ._2C-4BnEeZJU5oEzfDvD50N {
    align-self: center;
  }
}
