._2YoY9-4bNiEAoX7kwjl_J7 {
  font-size: var(--fontSize-m);
  overflow-y: auto;
  height: 100vh;
  background: var(--color-modal-overlay)
    url(/assets/background_login.jpg) no-repeat top center;
  background-size: cover;
}

._1ud_3VJDeXeNnb9Q1C_yqS {
  font-size: var(--fontSize-m);
  overflow-y: auto;
  height: 100vh;
  background-size: cover;
}

._2v1iqcYrAsckl6y6Axz8Jq {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

._3ftOCE5leUjsjhyt2fep00 {
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  height: 100%;
  padding-top: 90px;
}

._3ftOCE5leUjsjhyt2fep00 {
  padding-top: 0px;
}

._3ftOCE5leUjsjhyt2fep00 .qi2YT3f0aUZFDsLt8QGXM .Fc33MMan4PySSZxxZUFXK {
  margin: 4px auto;
  display: inline-block;
  width: 44%;
  height: 1px;
  border-top: 1px solid var(--color-primary3);
}

._3ftOCE5leUjsjhyt2fep00 .qi2YT3f0aUZFDsLt8QGXM ._1Psv10wWXJym6D7TDgi6PQ {
  margin: 4px 10px;
  display: inline-block;
  height: 1px;
}

._19ZYscoxMzuCruhLoAS40m {
  /* position: fixed; */
  height: 60px;
  width: 100%;
  z-index: 9999;
}

._19ZYscoxMzuCruhLoAS40m fieldset {
  margin-left: 0;
  margin-right: 0;
}

._19ZYscoxMzuCruhLoAS40m span {
  margin-right: 10px !important;
}
._19ZYscoxMzuCruhLoAS40m._3XrtiBtEKu8GcDIADiDHZu {
  background-color: var(--color-login-scrollTopHeader-bg);
}

._1KHxfT_WAKX-AP0A3RYd9d {
  position: relative;
  height: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

._3ftOCE5leUjsjhyt2fep00 ._1KHxfT_WAKX-AP0A3RYd9d {
  width: 440px;
}

@media (max-width: 767px) {
  ._3ftOCE5leUjsjhyt2fep00 ._1KHxfT_WAKX-AP0A3RYd9d {
    width: auto;
  }
}

._3Qu7OInNr9SrbOD7qDm2ni {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

._9hOYS_SkWgapB1gPLDsjz,
._2uj5FKQcWARdDqNO8qlpfv {
  white-space: nowrap;
}

._9hOYS_SkWgapB1gPLDsjz {
  width: 100px;
  margin: 20px;
  margin: 5px auto;
  position: relative;
}

._2uj5FKQcWARdDqNO8qlpfv {
  width: 320px;
  height: 160px;
  margin: 5px auto;
  position: relative;
}

._9hOYS_SkWgapB1gPLDsjz img,
._2uj5FKQcWARdDqNO8qlpfv img {
  position: absolute;
  top: 0;
  bottom: 0;
  object-fit: contain;
  left: 0;
  right: 0;
  margin: auto;
}

.stX67SbQNNl7cXH0vZaoG {
  width: 240px;
  height: 120px;
}

._3meVhCk9B5tx9XhNvc5V3l {
  width: 320px;
  height: 160px;
}

._2DqvLaSkkeEO8QPV1jVsh5 {
  width: 420px;
  height: 210px;
}

._3DU-RVBxK5y0fcrCk_VY6v {
  width: 420px;
  height: 210px;
  margin-bottom: 50px;
}

@media (min-width: 767px) and (max-width: 1919px) {
  ._3DU-RVBxK5y0fcrCk_VY6v {
    width: 240px;
    height: 120px;
    margin-top: 40px;
  }
}

@media (min-width: 769px) and (max-width: 1919px) {
  ._3TVNF5RTqp-iQNlKOanluB {
    padding-top: 60px;
  }
}

@media (min-width: 1920px) {
  ._3DU-RVBxK5y0fcrCk_VY6v {
    width: 420px;
    height: 210px;
    margin-top: 40px;
  }
}

._250bxFnICvz0GFq_TQG16C,
._2eulobNWSPP1QO-Q7gOvqZ > p:nth-child(1) {
  color: var(--color-login-primary1);
  font-weight: bold;
}

._1x83FE1avAH9HOOrNQ1OMr {
  margin-top: 45px;
}

._2O5ECSSFG0lidG6BqOGdlY {
  width: calc(100% - 10px);
  height: 50px;
  padding-left: 10px;
}

.a4-1xTg4yma0F1teU04UA {
  color: var(--color-login-error);
  /* position: absolute; */
  left: 0;
  right: 0;
  text-align: center;
}

._3D3GnA1yLWcDielzXe37Mh {
  margin: 15px 0;
}

._2lq631vGpFU5OijP8z4bE1 {
  color: var(--color-button-login-link-font);
}

._2lq631vGpFU5OijP8z4bE1:hover {
  color: var(--color-button-link-font-hover);
}

._3TVNF5RTqp-iQNlKOanluB {
  text-align: center;
  padding-bottom: 5rem;
}

._61R7QG3fzODpOv4zn3-_a {
  height: calc(100% - 60px);
}

._22f_zhMdqG0fk4AMY7zOp {
  height: 5rem;
  bottom: 0;
  position: absolute;
  text-align: center;
  /* padding: 80px 0; */
  align-self: flex-start;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

._3U_hCoOmR5xxfuRfwRExIM {
  font-size: var(--fontSize-s);
  width: 240px;
  line-height: 27px;
  margin: 0;
  margin-bottom: 100px;
}

._3j79wCdvRzsb6DIYOGhFGW {
  text-align: right;
  margin-bottom: 50px;
  padding-right: 50px;
}

._24r7qB46guqTnb646awvFt {
  margin-top: 20px;
  margin-bottom: 50px;
  text-align: center;
}

._1-WZpqoOv9462IcQtGZzFO {
  color: var(--color-login-primary2);
  padding-right: 10px;
}

.o7MKyNrmhSmfDi89XtE3l {
  text-decoration: none;
  color: var(--color-login-highlight-hover1);
  font-weight: bold;
  margin-bottom: 20px;
}

._33gH3ZjzH2sCjG-aT-4Dwh {
  height: auto;
  width: 330px;
}

@media (max-height: 890px) {
  ._22f_zhMdqG0fk4AMY7zOp {
    position: initial;
  }

  ._3TVNF5RTqp-iQNlKOanluB {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  ._2YoY9-4bNiEAoX7kwjl_J7:before {
    top: -200px;
    left: -50px;
  }

  ._2YoY9-4bNiEAoX7kwjl_J7:after {
    width: 100px;
  }

  ._2YoY9-4bNiEAoX7kwjl_J7 {
    font-size: var(--fontSize-s);
  }

  ._61R7QG3fzODpOv4zn3-_a {
    padding: 20px 20px 62px;
    height: calc(100% - 124px);
  }

  ._250bxFnICvz0GFq_TQG16C {
    margin-top: 40px;
  }

  ._3U_hCoOmR5xxfuRfwRExIM {
    width: 180px;
  }

  ._3DU-RVBxK5y0fcrCk_VY6v {
    width: 240px;
    height: 120px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  ._3TVNF5RTqp-iQNlKOanluB {
    padding-top: 20px;
  }

  ._9hOYS_SkWgapB1gPLDsjz,
  ._2uj5FKQcWARdDqNO8qlpfv,
  ._1UfaBzQnlnAcKQGUgShpgs > img,
  ._2uj5FKQcWARdDqNO8qlpfv > img {
    max-width: 320px;
  }
}

._2i5-P58MKpqM0SrlM0uTpg {
  width: 330px;
  min-height: 453px;
  color: var(--color-content-default1);
}

._1RWrVYm1KANo2LtnpcVQWs {
  margin-top: 0;
  margin-bottom: 20px;
}

._2l4lLM2scLO0lbzRzg0lHr {
  margin: 50px 0 0 0;
  text-align: center;
  font-size: larger;
}

._2f5PC5AvLySvkimWhkpjLO {
  display: block;
  margin: 20px 0 30px 0;
  text-align: center;
}

._1xLTl_EvCzO-HMMw3gQWs1 {
  display: block;
  width: fit-content;
  margin: 10px auto;
  text-transform: none;
  font-weight: normal;
}

._3zcmkctCuPTCBQ3oN7lGq- {
  text-align: center;
  padding: 20px;
}

.TxCwdSneuIpOViMjmXMte {
  width: 80px;
  transform: rotate(90deg);
}

.TxCwdSneuIpOViMjmXMte path {
  fill: var(--color-content-overlay3);
}

.TxCwdSneuIpOViMjmXMte circle {
  fill: var(--color-content-highlight-hover);
}

.a5kLK5mDfY4rFtBhc0NYa {
  width: 80px;
}

.a5kLK5mDfY4rFtBhc0NYa path {
  fill: var(--color-content-overlay3);
}

.a5kLK5mDfY4rFtBhc0NYa circle {
  fill: var(--color-content-highlight-hover);
}

@media (max-width: 345px) {
  ._9hOYS_SkWgapB1gPLDsjz,
  ._2uj5FKQcWARdDqNO8qlpfv,
  ._1UfaBzQnlnAcKQGUgShpgs > img,
  ._2uj5FKQcWARdDqNO8qlpfv > img {
    max-width: 100%;
  }
}

._61R7QG3fzODpOv4zn3-_a .z8mJRDP_YqmMeiwSZz_Sf {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

@media (max-width: 768px) {
  ._22f_zhMdqG0fk4AMY7zOp {
    position: initial;
  }
}

@media (max-width: 1024px) and (min-width: 769px) {
  ._61R7QG3fzODpOv4zn3-_a .z8mJRDP_YqmMeiwSZz_Sf {
    width: 100%;
    /* padding: 0 12px; */
  }
}

._61R7QG3fzODpOv4zn3-_a ._22OBu4IqEnRBS94DQVGviv {
  width: 100%;
  display: inline-block;
}

._61R7QG3fzODpOv4zn3-_a .Fc33MMan4PySSZxxZUFXK {
  width: 1px;
  height: 40%;
  margin: 19px auto;
  background-color: var(--color-primary3);
}

._61R7QG3fzODpOv4zn3-_a ._2eulobNWSPP1QO-Q7gOvqZ {
  width: 480px;
  display: inline-block;
}

._2Z_VaImQiW8FrJQaVxwcy- {
  width: 180px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

/* .ssoLogo:hover {
  -webkit-box-shadow: 0px 0px 27px 0px rgba(140,140,140,0.99);
  box-shadow: 0px 0px 27px 0px rgba(140,140,140,0.99);
  border-radius: 10px;
  transform: scale(1.1, 1.1);
} */

._2Z_VaImQiW8FrJQaVxwcy- > img {
  width: 140px;
  height: 140px;
  margin: 30px auto 3px;
}

._3ftOCE5leUjsjhyt2fep00 .z8mJRDP_YqmMeiwSZz_Sf,
._3ftOCE5leUjsjhyt2fep00 ._22OBu4IqEnRBS94DQVGviv,
._3ftOCE5leUjsjhyt2fep00 ._2eulobNWSPP1QO-Q7gOvqZ {
  display: block;
  width: 100%;
}

._3ftOCE5leUjsjhyt2fep00 .Fc33MMan4PySSZxxZUFXK {
  margin: 60px auto;
  width: 60px;
  height: 1px;
  border-top: 1px solid var(--color-primary3);
}

._3ftOCE5leUjsjhyt2fep00 ._3j79wCdvRzsb6DIYOGhFGW {
  padding-right: 0px;
}

._2gvNkr3rTB4ozvpeqrmDzt {
  width: 480px;
  display: inline-block;
}

._3uqNzGrBIW5Qg5SCyFEvbc {
  width: 100%;
}

.OZ34LmxGgcssqqBaHRnt4 {
  width: 100%;
}

._61R7QG3fzODpOv4zn3-_a .qi2YT3f0aUZFDsLt8QGXM {
  width: 41px;
  margin: 0 60px;
}

.z8mJRDP_YqmMeiwSZz_Sf .qi2YT3f0aUZFDsLt8QGXM {
  margin: 30px 0;
}

._2XQqqfG9vM9WapvOF6xjjB {
  width: 90%;
}

@media (max-width: 1024px) {
  ._61R7QG3fzODpOv4zn3-_a .qi2YT3f0aUZFDsLt8QGXM {
    margin: 0;
  }
}

@media (max-width: 768px) {
  ._61R7QG3fzODpOv4zn3-_a .z8mJRDP_YqmMeiwSZz_Sf,
  ._61R7QG3fzODpOv4zn3-_a {
    display: block;
    /* width: 100%; */
  }

  ._61R7QG3fzODpOv4zn3-_a ._2gvNkr3rTB4ozvpeqrmDzt,
  ._61R7QG3fzODpOv4zn3-_a ._2eulobNWSPP1QO-Q7gOvqZ,
  ._61R7QG3fzODpOv4zn3-_a .qi2YT3f0aUZFDsLt8QGXM {
    width: 100%;
    max-width: 480px;
  }

  ._61R7QG3fzODpOv4zn3-_a .qi2YT3f0aUZFDsLt8QGXM {
    margin: 20px 0;
    display: inline-block;
  }

  ._61R7QG3fzODpOv4zn3-_a ._1Psv10wWXJym6D7TDgi6PQ {
    width: 10%;
    display: inline-block;
  }

  ._61R7QG3fzODpOv4zn3-_a .Fc33MMan4PySSZxxZUFXK {
    margin: 4px auto;
    display: inline-block;
    width: 45%;
    height: 1px;
    border-top: 1px solid var(--color-primary3);
  }

  ._61R7QG3fzODpOv4zn3-_a ._3j79wCdvRzsb6DIYOGhFGW {
    padding-right: 0px;
  }
}

._3IbRAaJevJU9z6og5BN7h_ {
  height: 24px;
  width: 24px;
  stroke: var(--color-primary1);
  margin: 8px 0;
}

._3IbRAaJevJU9z6og5BN7h_ {
  transform: rotate(-90deg);
}

details summary:hover ._3IbRAaJevJU9z6og5BN7h_ {
  stroke: var(--color-secondary3);
}
