:root {
  --EPGGridText-textContainer-padding: 10px;
  --EPGGridText-startTime-fontSize: var(--fontSize-l);
  --EPGGridText-title-fontSize: var(--fontSize-m);
  --EPGGridText-kind-fontSize: var(--fontSize-s);
  --EPGGridText-title-margin: 10px 0 0 0;
}

@media (max-width: 1023px) {
  :root {
    --EPGGridText-textContainer-padding: 5px;
    --EPGGridText-startTime-fontSize: var(--fontSize-s);
    --EPGGridText-title-fontSize: var(--fontSize-s);
    --EPGGridText-title-margin: 0 0 0 0;
    --EPGGridText-kind-fontSize: var(--fontSize-xs);
  }
}

.JWBXhUDnOD4enmvkU_xXM {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: pointer;
}

._1b_RTue1olxwxsmssD56si {
  background-color: var(--color-secondary3);
  padding: var(--EPGGridText-textContainer-padding);
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

._2FgFGMWEzwdUyNR-injAJ2 {
  direction: rtl !important;
}
._2FgFGMWEzwdUyNR-injAJ2 ._4OJzOTaZCLhl3vdVpdwNL{
  left: unset;
}

._2FgFGMWEzwdUyNR-injAJ2 #_2Hfxl-zKorAgRokfG4ZNvc {
  direction: ltr !important;
}


._2o4aV2RPuQj5AzE7r6sDMr {
  font-size: var(--EPGGridText-startTime-fontSize);
  font-weight: 700;
  color: var(--color-content-default1);
  padding-right: 5px;
}

._3Lvjlgbl2VyPp_YIxGq37a {
  font-size: var(--EPGGridText-kind-fontSize);
  color: var(--color-content-default3);
}

._3Lvjlgbl2VyPp_YIxGq37a:before {
  content: '(';
  display: inline-block;
}

._3Lvjlgbl2VyPp_YIxGq37a:after {
  content: ')';
  display: inline-block;
}

._1dfnZw4tMFdvzVlNf9YOqr {
  font-size: var(--EPGGridText-title-fontSize);
  margin: var(--EPGGridText-title-margin);
  color: var(--color-content-default2);
  max-height: 45px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

._4OJzOTaZCLhl3vdVpdwNL {
  font-size: var(--EPGGridText-kind-fontSize);
  margin: 0;
  color: var(--color-content-default3);
  text-transform: uppercase;
  position: absolute;
  bottom: var(--EPGGridText-textContainer-padding);
  left: var(--EPGGridText-textContainer-padding);
}

._3wGSRyidAf2qIARaokcuCo {
  position: absolute;
  top: var(--EPGGridText-textContainer-padding);
  right: var(--EPGGridText-textContainer-padding);
  fill: var(--color-content-default3);
  width: 20px;
}

._2fNLyHKPQfhjwbXjWeEs8C {
  position: absolute;
  top: var(--EPGGridText-textContainer-padding);
  right: var(--EPGGridText-textContainer-padding);
  fill: var(--color-primary1);
  width: 20px;
}

.remVqyJh8UoEdvVntPEKG {
  position: absolute;
  top: var(--EPGGridText-textContainer-padding);
  right: var(--EPGGridText-textContainer-padding);
  width: 150px;
  height: 50px;
}

._3VuWdnSPosUjVBBukwu03q {
  font-size: var(--fontSize-s);
  font-weight: normal;
}