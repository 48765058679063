._36iBm6a4tVlKPn14ITWApc {
  max-width: 1024px;
  margin: 0 auto 100px;
}

.t4a4PfeiT31Ve9Hdx8F65 {
  max-width: 1024px;
}

._2yt3GglvnoObHp9KRzneHs {
  position: relative;
  margin-top: 160px;
  margin-bottom: 60px;
}

._2diWuU9S3Q_i8b1M9dAme0 {
  position: relative;
}

._3eWlpfROd4vhkGPoP_90M_ {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  margin-top: 40px;
  margin-bottom: 40px;
  display: grid;
}

._3Tcda1ZznrSafgNC59JJ6l summary span:nth-child(2) {
  margin-left: 15px;
}

._2m5pMuQJZf-T81NwnbRJNb {
  display: inline-block;
  line-height: normal;
  margin-left: 20px;
}

._2m5pMuQJZf-T81NwnbRJNb fieldset {
  margin-left: 0;
  margin-right: 0;
}

._2m5pMuQJZf-T81NwnbRJNb > a > span {
  margin-bottom: 0;
}

._2MNsu5y1UjON4XBGLjGHv0 {
  animation-name: ecxa_k9OXYlbb7rAv96hK;
  -webkit-animation-name: ecxa_k9OXYlbb7rAv96hK;

  animation-duration: 0.5s;
  -webkit-animation-duration: 0.5s;

  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;

  visibility: visible !important;
}

._3W5ruekIvdU-5WXG1lHCar {
  color: var(--color-content-default1);
}

.OUb5xp3iLbYXCMxHsqFEu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 23px;
}

._1htFB6VC8dM4c6zALX2wBB {
  color: var(--color-highlight-hover1);
  cursor: pointer;
  margin-left: 45px;
  margin-top: 10px;
}

._2l_gzGkclL7LqfcOV0NZC1 {
  min-height: 38px;
  margin-left: 45px;
  font-size: 14px;
  color: var(--color-primary3);
}

._1YG6w8d_cq0JuTmf23rIZL {
  margin-bottom: 5px;
  margin-top: 0;
}

@keyframes ecxa_k9OXYlbb7rAv96hK {
  0% {
    transform: translateX(150%);
  }

  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes ecxa_k9OXYlbb7rAv96hK {
  0% {
    -webkit-transform: translateX(150%);
  }

  100% {
    -webkit-transform: translateX(0%);
  }
}

.A6OO3b2UZ4gbyBgQCS9oV {
  padding-left: 5px;
  margin-top: 30px;
  font-size: var(--fontSize-xl);
  font-weight: bold;
  color: var(--color-content-default1);
}

.GmGx2ZZT5cqE8wRSErjmw {
  margin-top: 40px;
  margin-bottom: 40px;
  display: grid;
}

._2Z0k4effJHuZigBfnjAqNz {
  text-align: center;
  padding: 10px 0px;
  color: var(--color-content-default2);
}

._2Z0k4effJHuZigBfnjAqNz h2 {
  padding-left: 5px;
  margin-top: 160px;
  font-size: var(--fontSize-xxl);
  font-weight: 600;
  color: var(--color-content-default1);
}

._2Z0k4effJHuZigBfnjAqNz > ._3FuRfRLvl3jen1SsFvzhLY {
  margin: 30px auto;
  font-size: var(--fontSize-m);
}

.w1PZTFCuWQ4o5EpzklcdW,
._2mvHBTtbGz_WAbe-469jH,
._10C6KwQxw30n5Y7iw_eBda {
  margin-right: 10px;
}

.w1PZTFCuWQ4o5EpzklcdW,
._2mvHBTtbGz_WAbe-469jH,
._10C6KwQxw30n5Y7iw_eBda,
.w1PZTFCuWQ4o5EpzklcdW > svg,
._2mvHBTtbGz_WAbe-469jH > svg,
._10C6KwQxw30n5Y7iw_eBda > svg {
  vertical-align: middle;
  height: 30px;
}

.w1PZTFCuWQ4o5EpzklcdW circle {
  fill: var(--color-content-highlight-hover);
}

.w1PZTFCuWQ4o5EpzklcdW path {
  fill: var(--color-content-overlay3);
}

._2mvHBTtbGz_WAbe-469jH circle {
  fill: var(--color-content-error);
}

._2mvHBTtbGz_WAbe-469jH path {
  fill: var(--color-content-default1);
}

._1-3ip8C00kVuwU8uA0cMGo {
  width: 100%;
  display: inline-block;
  margin-top: 30px;
}

._10C6KwQxw30n5Y7iw_eBda circle {
  fill: var(--color-content-default2);
}

._10C6KwQxw30n5Y7iw_eBda path {
  fill: var(--color-content-overlay3);
}

._1mU80r9DuUFnf8sVhmeDam {
  float: left;
}

._1JqOfxFvIe9JZHyRRZDsrD {
  float: right;
  margin-right: 10px;
}

._2J4mfy-isdUWf7e5cP5JkR {
  float: right;
}

.thdW1-hoqL6phts_QRm5l {
  display: inline-block;
  margin-top: 30px;
}

._3gwoGUYnQzlMQL9Vvla08v {
  max-width: 100%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--color-content-default3);
  outline: transparent;
  margin-bottom: 15px;
  padding-left: 10px;
  font-size: var(--fontSize-m);
  color: var(--color-content-default1);
}

._3Po7cQ6aocMjzUJSRlkW9x {
  padding-left: 10px;
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  line-height: 1.43;
}

._3GLI_x-K1k7VUVuScPSIg5 fieldset {
  border-bottom: 1px solid var(--color-content-default3);
}

._3GLI_x-K1k7VUVuScPSIg5 fieldset details {
  z-index: 0;
}

._1q9wduA740oT4ycg5Nq_10 {
  color: var(--color-content-highlight-hover);
  font-size: var(--fontSize-s);
}

._2UKTZUo7aLoEHaaRICVxQv {
  display: block;
  color: var(--color-error);
  font-size: var(--fontSize-s);
  margin-top: 10px;
}

._3WjEdVaFm5Wc_MQ7tBL_FQ {
  text-decoration: none;
  font-size: var(--fontSize-m);
  color: var(--color-content-highlight-hover);
}

._3673Gv-XewiJj7vrXSFL7m {
  padding: 10px;
  width: calc(100% - 20px);
  background-color: var(--color-content-overlay1);
  color: var(--color-content-default1);
}

._3673Gv-XewiJj7vrXSFL7m > ._3Po7cQ6aocMjzUJSRlkW9x {
  color: var(--color-content-default1);
}

._1T824e5IfNDcm3bVtEJn-X {
  display: block;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  line-height: 60px;
  background-color: var(--color-nav-background);
}

._263ZJywDced9zSxeUCYTzc {
  z-index: 2;
}

._1SEEGStWX8fJACJHuHd0rj {
  z-index: 1;
}

._1NMT2re1MnV9M-lUIJLppe {
  height: 46px;
  margin-top: 7px;
  border-right: 1px solid white;
  padding-right: 40px;
}

._2afrDJJG9Ca_dAX2_loOuX {
  float: left;
  margin-left: 50px;
  vertical-align: middle;
  display: inline-flex;
}

._2sd9cY2zmNCDzJ2ZFpnOpt {
  float: right;
  margin-right: 50px;
  vertical-align: middle;
}

.f3C2TSRIhj0ZO5TKVVuTA {
  cursor: pointer;
  color: var(--color-content-default1);
  float: right;
  font-size: var(--fontSize-m);
  font-weight: bold;
  text-decoration: none;
}

.BT72fHz9RNzVyLBnLYDVP {
  color: var(--color-content-default3);
  vertical-align: middle;
}

.BT72fHz9RNzVyLBnLYDVP > span {
  color: #fff;
  cursor: pointer;
}

._1IFqh8J9ytJwf6c0IcrmLJ {
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
  margin-block-start: 5px;
  margin-block-end: 0;
  padding-inline-start: 30px;
}

._9le9pySkbnKhfiTN82MoL {
  color: var(--color-content-highlight-hover);
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
  cursor: pointer;
}

._2pj-lAhjDp3BaI64J3jDkF {
  width: 277px;
  min-height: 38px;
  font-size: 14px;
  color: var(--color-primary3);
}

._2-XgpaqhBp2fMEFWYFoigg {
  margin-top: 30px;
}

._3PucZr17nxqFuaMzEa9DAd {
  display: flex;
  border-top: 6px solid #ecc94b;
  background-color: #faf089;
  padding: 20px 10px 10px 10px;
  color: #000;
  margin: 8px;
}

._3PucZr17nxqFuaMzEa9DAd h3 {
  margin-top: unset;
  margin-right: 10px;
  margin-left: 10px;
  color: #975a16;
}

._1AJD4WL_Zmiq1g5JToqZrs,
._1AJD4WL_Zmiq1g5JToqZrs svg {
  height: 30px;
  width: 30px;
  fill: #ecc94b;
}

@media (max-width: 1023px) {
  ._2yt3GglvnoObHp9KRzneHs {
    margin-top: 100px;
  }
}

@media (max-width: 767px) {
  ._2yt3GglvnoObHp9KRzneHs {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  ._2afrDJJG9Ca_dAX2_loOuX {
    margin-left: 20px;
  }

  ._2sd9cY2zmNCDzJ2ZFpnOpt {
    margin-right: 20px;
  }

  ._3673Gv-XewiJj7vrXSFL7m {
    padding: 10px;
    width: calc(100% - 20px);
  }
}

._2CEq8warlkIs9y1qV29G2t {
  color: var(--color-input-password-valid-policy);
}
