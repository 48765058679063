:root {
  --width: 420px;
  --contextHeight: 144px;
}

._3yAOVJ1RaIeUnAWU0rAaQc {
  width: 100%;
  max-width: var(--width);
  height: var(--contextHeight);
  background-color: var(--color-secondary3);
  position: relative;
  margin-bottom: 5px;
}

._3yAOVJ1RaIeUnAWU0rAaQc:hover {
  background-color: var(--color-secondary2);
}

._1Ky4zblieBWJCCGkXLIKA1 {
  width: 100%;
  display: inline-flex;
  color: var(--color-content-default1);
}

._3qJLXnzxJIqQ2yZcjOPDLB {
  width: 70px;
  height: 70px;
  float: left;
  margin: 35px 20px 20px;
}

._2J0r1HN9CDD25VPt_NtSMI {
  display: block;
  margin-top: 35px;
  margin-bottom: 30px;
}

._3H5RfnaT4-B3WEAeSRKEXO {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: var(--fontSize-s);
  color: var(--color-content-default3);
}
._3HQyD3wJUZHWXM6QoluCGM {
  font-size: var(--fontSize-l);
  font-weight: bold;
  margin: 0;
}

._1qAkzwGD_3PqJyD4y2I9J7 {
  font-size: var(--fontSize-m);
  font-weight: bold;
  margin: 5px 0 5px;
}

._2AQCoirOTDPLXVTJ7HQ0j9 {
  font-size: var(--fontSize-m);
  margin-right: 20px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ZpLKN01WgWT8vXewMXlz7 {
  text-transform: uppercase;
  color: var(--color-content-default3);
  font-size: var(--fontSize-s);
  margin-left: 5px;
}

._1tMRqgJP6-HyH_p9ajO9Uo {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--color-content-progressbar-buffer-empty1);
  top: 0;
}

.EqXUpNlwT5LH-KAeApxDC {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--color-content-progressbar-buffer-empty1);
  bottom: 0;
}

._1uNla9yTM1bJPx-V0plohx {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

._1uNla9yTM1bJPx-V0plohx::before,
._1uNla9yTM1bJPx-V0plohx::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

._1uNla9yTM1bJPx-V0plohx::before {
  border-width: 20px;
}

._1uNla9yTM1bJPx-V0plohx::after {
  border-width: 20px;
  border-right-color: var(--color-content-highlight-hover);
  border-top-color: var(--color-content-highlight-hover);
}

.tPh04C-X0QuQ-iPDRkvp8 {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}
.nPGoIki8__UKdvCQ-3Juw {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 60px;
  height: 60px;
  z-index: 1;
}
._3XDa27hacbh-g5uqmTWrFJ {
  fill: var(--color-thumbnail-highlight-hover1);
}
._3MJ_T-rz8ON0YxAJs2E-A8 {
  width: 16px;
  fill: var(--color-content-default1);
}
._2ABs7__ZJ_CymrBxF7m2GQ,
._2nDDYht7glb3zGoegnq5bu {
  position: absolute;
  bottom: 65px;
  right: 15px;
  z-index: 1;
  width: 20px;
  height: 20px;
}
._2nDDYht7glb3zGoegnq5bu {
  opacity: 0;
}

._25IxKQzDFD0UpsqKm3KH1L {
  opacity: 1;
}

._3OMIoql4dAqLAGa23K0x1O {
  top: 150px;
  left: 50%;
  transform: translate(-10px, -50%);
}
@media (max-width: 950px) {
  ._3SCAI1Y_EVDxiix_OmI2oM {
    width: 60px;
    height: 60px;
  }
}

@media (max-width: 767px) {
  ._3HQyD3wJUZHWXM6QoluCGM {
    font-size: var(--fontSize-m);
  }

  ._1qAkzwGD_3PqJyD4y2I9J7,
  ._2AQCoirOTDPLXVTJ7HQ0j9 {
    font-size: var(--fontSize-s);
  }

  .ZpLKN01WgWT8vXewMXlz7 {
    font-size: var(--fontSize-xs);
  }

  ._3SCAI1Y_EVDxiix_OmI2oM {
    width: 60px;
    height: 60px;
  }
}
