._1L06VhHcZj2cbUXOKpHHOA {
  margin: 10px auto 50px;
  text-align: center;
  color: var(--color-content-default1);
}

._3ZLpm5Ib-iPherXGpEKevO {
  max-width: 100px !important;
}

._1Yf6GQaPYurZxcfyzC2utJ {
  max-width: 280px;
  height: auto;
  width:100%;
  margin-bottom: 20px;
}

._2W9sg14p859MYeagq9Xkyp {
  max-width: 150px;
  height: auto;
  width:100%;
  margin-bottom: 20px;
}


@media (max-width: 767px) {
  ._1Yf6GQaPYurZxcfyzC2utJ {
    max-width: 180px;
  }
}
