:root {
  --PlayerOverlay-animationDuration: 0.5s;
  --PlayerOverlay-iconDuration: 0.3s;
}
._2hlD5jbbozgarClltALcbt {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--color-player-overlay);
  display: flex;
  align-items: center;
  justify-content: center;
}

._1EsQ9DQjrToUPgF2FoKMXG {
  position: absolute;
  left: 44px;
  top: 30px;
  right: 50px;
  color: var(--color-player-default1);
  display: flex;
  align-items: center;
  height: 60px;
}

.qjsV8pKjGAWNNQjsG48iP {
  width: 24px;
  height: 32px;
  transform: rotate(180deg);
  stroke: var(--color-player-default1);
  stroke-width: 2px;
  cursor: pointer;
  transition: stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

.qjsV8pKjGAWNNQjsG48iP:hover {
  stroke: var(--color-player-highlight-hover1);
}

.XgBtBTrsLpJO0ocRx3XTN {
  width: 80px;
  height: 80px;
  display: inline-block;
  margin-left: 15px;
}

._1nNom6v4zCkdRaNtr1yzkS {
  display: inline-block;
  margin-left: 20px;
  font-size: var(--fontsize-l);
}

._1F5lxANVdpQCGCUsH6RpCi {
  font-weight: bold;
  margin: 0;
}

._1qq6Dro6qah_7tz5PtcQZe {
  margin: 0;
}

._1F5lxANVdpQCGCUsH6RpCi + ._1qq6Dro6qah_7tz5PtcQZe {
  margin-top: 10px;
}

._3L9cco774bHQRu2dQ_EYo6 {
  justify-content: center;
  display: flex;
  align-items: center;
}

._3ouJWjRkQsX4sHUrKAT7kz {
  display: inline-block;
}

._2JVyTox33v4fShxkicIA64 > svg {
  cursor: pointer;
  margin: 0 auto;
  width: 100px;
  height: 100px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}

._2JVyTox33v4fShxkicIA64 circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._2JVyTox33v4fShxkicIA64:hover {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

.AZN0Kt8u4GwbBH7Jnw5x6 {
  width: 60px;
  height: 60px;
  margin: 100px;
  cursor: pointer;
  display: inline-block;
  z-index: 10000;
  color: var(--color-player-default1);
  fill: var(--color-player-default1);
  stroke: var(--color-player-default1);
  stroke-width: 0.4;
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}
._2KPpicsQ8LMhJjGNLkG1S8 {
  width: 60px;
  height: 60px;
  margin: 100px;
  cursor: pointer;
  display: inline-block;
  z-index: 10000;
  color: var(--color-primary3);
  fill: var(--color-primary3);
  stroke: var(--color-primary3);
  stroke-width: 0.4;
  transition: fill var(--PlayerOverlay-animationDuration) ease-in-out,
    stroke var(--PlayerOverlay-animationDuration) ease-in-out;
}
.AZN0Kt8u4GwbBH7Jnw5x6:hover {
  fill: var(--color-player-highlight-hover1);
  stroke: var(--color-player-highlight-hover1);
}

._2VJzmtxnqfMjMSlMtyCYQJ {
  background: var(--color-content-progressbar-buffer-empty2);
  position: absolute;
  width: calc(100% - 100px);
  bottom: 100px;
  left: 50px;
  right: 50px;
  height: 6px;
  border-radius: 6px;
}

._2U6Bc7d6rq5I9YhlZe1wvO {
  position: absolute;
  right: 50px;
  top: calc(50vh - 20px);
  height: 40px;
  width: 40px;
  cursor: pointer;
  fill: var(--color-player-default1);
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out;
}

._2U6Bc7d6rq5I9YhlZe1wvO:hover {
  fill: var(--color-player-highlight-hover1);
}

._2OIKaKCnPXh7hl0_xA-UbW {
  position: absolute;
  bottom: 107px;
  color: var(--color-player-default1);
  right: 50px;
}
._1DZVAFbyI2Pw24jwcyAYD3 {
  top: var(--EPGGridText-textContainer-padding);
  right: var(--EPGGridText-textContainer-padding);
  fill: var(--color-primary1);
  width: 20px;
  height: 20px;
  cursor: pointer;
}
._1bvk-3w9kLG99lhvsiJ09k {
  color: var(--color-player-default1);
  border: none;
  background: transparent;
  position: absolute;
  bottom: 30px;
  right: 50px;
  display: flex;
}
.fF4Gr-VHVdjswD3rqGaID {
  color: var(--color-player-default1);
  border: none;
  background: transparent;
  position: absolute;
  bottom: 30px;
  left: 50px;
  display: flex;
}

._1bvk-3w9kLG99lhvsiJ09k > * {
  margin: 0 20px;
}

._1g1UU7xIUGTGYkhTjTLVNg {
  cursor: pointer;
  fill: var(--color-player-default1);
  stroke: var(--color-player-default1);
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out,
    stroke var(--PlayerOverlay-iconDuration) ease-in-out;
}

._1g1UU7xIUGTGYkhTjTLVNg:hover {
  fill: var(--color-player-highlight-hover1);
  stroke: var(--color-player-highlight-hover1);
}

._34Eo9oCL7l9AFAwfwpfVZa {
  position: absolute;
  bottom: 200px;
  right: 40px;
  width: 400px;
  height: 110px;
  background: var(--color-content-overlay1);
  z-index: 2000;
  padding: 10px;
  box-sizing: border-box;
}

._2ouvw3LdyvaberfX1Or_rF {
  height: 90px;
  width: 160px;
  margin-right: 10px;
  display: inline-block;
}

._1CZaFcB3-AUaKfNXq_FYaI {
  width: 210px;
  height: 90px;
  position: absolute;
  display: inline-flex;
  right: 10px;
  align-items: center;
}

.S0XliWnsWzSluKeGLCly6 {
  width: 174px;
}

._1ox_Fsc501aBFBIcWMErLc {
  font-size: var(--fontSize-s);
  color: var(--color-player-default2);
  margin-bottom: 5px;
}
._25gq3PtU7OJOeEooV8unim {
  margin-top: 5px;
  font-size: var(--fontSize-m);
  font-weight: bold;
  color: var(--color-player-default1);
  max-width: 180px;
  text-overflow: ellipsis;
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 3.6em;
}

/* hide ... if we have text, which is less than or equal to max lines */
._1wAh0w9Z5XbLY5nBwWaqPq:after {
  /* points in the end */
  content: "";
  /* absolute position */
  position: absolute;
  /* set position to right bottom corner of text */
  right: 0;
  /* set width and height */
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  /* bg color = bg color under block */
  background: white;
}

._1JZyNcSfzvrj_Hh1wfUq85 {
  position: absolute;
  right: 5px;
  top: 20px;
  width: 24px;
  height: 24px;
  fill: var(--color-player-default1);
  cursor: pointer;
  transition: fill var(--PlayerOverlay-iconDuration) ease-in-out;
}

._1JZyNcSfzvrj_Hh1wfUq85:hover {
  fill: var(--color-player-highlight-hover1);
}

.-KgUSOyHolePiU4ECVC7U {
  position: absolute;
  top: 20px;
  right: 100px;
  width: 200px;
  background: white;
  color: black;
  z-index: 1001;
}

/* Overlay aninmation */
._37dj_ucWxnV4lm5TQ98oL8 {
  opacity: 0;
}

._2JcUTDsY-kmw7zqaSHAITQ {
  opacity: 0;
}

._1yJsyLWWqxzH0T-EiHgENw {
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
  opacity: 1;
}

._2hTSP5-2sNCCmxNcMA5V56 {
  opacity: 1;
}

._3Bws16_-BY4ird3bbIce2l {
  opacity: 1;
}

._1zb47cVnAKTT-x3bRbsESn {
  opacity: 0;
  transition: opacity var(--PlayerOverlay-animationDuration) ease-in-out;
}

._3-OApC6H_2JiuDxr103A9J {
  opacity: 0;
  visibility: hidden;
}

@media (max-width: 1023px) {
  .AZN0Kt8u4GwbBH7Jnw5x6 {
    margin: 50px;
    height: 50px;
    width: 50px;
  }

  ._2JVyTox33v4fShxkicIA64 > svg {
    height: 80px;
    width: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  ._2OIKaKCnPXh7hl0_xA-UbW {
    bottom: 160px;
  }

  ._2VJzmtxnqfMjMSlMtyCYQJ {
    bottom: 150px;
  }

  ._1bvk-3w9kLG99lhvsiJ09k,
  .fF4Gr-VHVdjswD3rqGaID {
    bottom: 80px;
  }
}

@media (max-width: 767px) {
  ._1EsQ9DQjrToUPgF2FoKMXG {
    left: 15px;
    top: 5px;
    display: inline-flex;
  }
  .XgBtBTrsLpJO0ocRx3XTN {
    margin-left: 10px;
  }

  .qjsV8pKjGAWNNQjsG48iP {
    height: 20px;
    width: 13px;
  }

  ._1nNom6v4zCkdRaNtr1yzkS {
    margin-left: 10px;
  }

  ._1F5lxANVdpQCGCUsH6RpCi {
    font-size: var(--fontSize-s);
  }

  ._1qq6Dro6qah_7tz5PtcQZe,
  .Tj59QEy3bS5AUQExFDCHN {
    font-size: var(--fontSize-xs);
  }

  ._1qq6Dro6qah_7tz5PtcQZe {
    margin: 0;
  }

  .AZN0Kt8u4GwbBH7Jnw5x6 {
    margin: 20px;
    height: 40px;
    width: 40px;
  }

  ._2JVyTox33v4fShxkicIA64 > svg {
    height: 60px;
    width: 60px;
  }

  ._2U6Bc7d6rq5I9YhlZe1wvO {
    height: 25px;
    width: 25px;
  }

  ._2OIKaKCnPXh7hl0_xA-UbW {
    font-size: var(--fontSize-xs);
  }

  ._2VJzmtxnqfMjMSlMtyCYQJ {
    width: calc(100% - 20px);
    left: 10px;
    right: 10px;
    bottom: 120px;
  }

  ._34Eo9oCL7l9AFAwfwpfVZa {
    width: 340px;
    height: 72px;
    bottom: 20%;
    left: 10px;
  }

  ._2ouvw3LdyvaberfX1Or_rF {
    width: 92px;
    height: 52px;
    margin-right: 5px;
  }

  ._1CZaFcB3-AUaKfNXq_FYaI {
    width: 208px;
    height: 52px;
    right: 18px;
  }

  ._25gq3PtU7OJOeEooV8unim {
    font-size: var(--fontSize-s);
    white-space: nowrap;
  }

  ._1ox_Fsc501aBFBIcWMErLc {
    font-size: var(--fontSize-xs);
  }

  ._1JZyNcSfzvrj_Hh1wfUq85 {
    top: 13px;
    right: 0;
  }

  ._1bvk-3w9kLG99lhvsiJ09k {
    right: 10px;
  }

  ._1bvk-3w9kLG99lhvsiJ09k,
  .fF4Gr-VHVdjswD3rqGaID {
    bottom: 80px;
  }

  ._1bvk-3w9kLG99lhvsiJ09k > * {
    margin: 0 10px;
  }
}

@media (max-width: 359px) {
  ._34Eo9oCL7l9AFAwfwpfVZa {
    bottom: 15%;
  }
}
