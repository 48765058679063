:root {
  --PageAsset-headerHeigth: 600px;
  --PageAsset-contentContainerPadding: 20px;
  --PageAsset-HidePosterPlayIconTop: -200px;
}

._2XfZInzSQ4OjWBjua2dapQ {
  margin-top: 0;
  display: flex;
}

._2IG2oA3rKWEzSKxd2A3N7D {
  margin-top: -100px;
}

._3bHOImQyC0WGt46qf_pcOc {
  float: left;
  position: relative;
  margin-right: 50px;
  height: 260px;
  width: auto;
}

._3bHOImQyC0WGt46qf_pcOc._1p89y2-shFwToRwzEjVJG_ {
  margin-left: 50px;
  margin-right: 0;
}
._3CrUzkUetqc3NBwntpAgYF {
  z-index: 50;
  width: 35px;
  height: 20px;
  text-align: left;
}
.pG6HuLEbIf7rpWHnbh9E8 {
  top: 8px;
  position: relative;
  z-index: 50;
  margin: auto;
  vertical-align: top;
  max-width: 35px;
  max-height: 20px;
}
._3bHOImQyC0WGt46qf_pcOc:hover ._21oEDdpLargl7fqAuW2-tr > svg {
  stroke: var(--color-thumbnail-highlight-hover1);
  fill: var(--color-thumbnail-highlight-hover1);
}

.DdRFhEnN9pu5wTtNqw48l {
  cursor: pointer;
  text-decoration: none;
}
._3r9kOiSY8FzWx9ZyqqZsXa svg,
._21oEDdpLargl7fqAuW2-tr svg {
  width: 56px;
  height: 56px;
}

._21oEDdpLargl7fqAuW2-tr {
  position: absolute;
  cursor: pointer;
  left: calc(50% - (56px / 2));
  top: calc(50% - (56px / 2));
  width: 56px;
  height: 56px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
}

._21oEDdpLargl7fqAuW2-tr circle,
._3r9kOiSY8FzWx9ZyqqZsXa circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._21oEDdpLargl7fqAuW2-tr:hover > svg {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

._3r9kOiSY8FzWx9ZyqqZsXa {
  position: absolute;
  cursor: pointer;
  top: -5px;
  width: 56px;
  height: 56px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
}

._3r9kOiSY8FzWx9ZyqqZsXa:hover svg {
  fill: var(--color-thumbnail-highlight-hover1);
  stroke: var(--color-thumbnail-highlight-hover1);
}

._3r9kOiSY8FzWx9ZyqqZsXa:hover svg {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

._1qyMpahUl3MC477Gg8WfP2 {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0.8;
  border-radius: 4px;
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  height: 32px;
  line-height: 32px;
  background-color: var(--color-background-asset);
  color: var(--color-thumbnail-primary1);
  font-size: var(--fontSize-xs);
  font-weight: normal;
  margin: 0;
}

._1hf7pZFoCmamh2NkN8hO6Y {
  position: relative;
  bottom: 3px;
  z-index: 50;
}

._3btChQ4uwzjpFFPSmBSuWh {
  position: absolute;
  top: -20px;
}

._30ntXaOYnWQcTA6j5ylpfa,
._3NUEmVLeI0MXL77wvxIwyB {
  position: relative;
  left: 70px;
}
._3NUEmVLeI0MXL77wvxIwyB {
  right: 70px;
}

.ZBYi1T3zTCGATm6qpz1fv {
  color: var(--color-thumbnail-primary1);
  text-transform: uppercase;
  font-size: var(--fontSize-xs);
}

._3zBHV-7cNNTIPMHKj6stGl {
  width: 170px;
}

._2YlXYbzsEa5wayxUxX4Mmu,
.t1sGQVQb-5ibKzCvy9TQ3 {
  position: absolute;
  color: var(--color-thumbnail-primary1);
  text-transform: uppercase;
  top: -20px;
  left: 16px;
}
.t1sGQVQb-5ibKzCvy9TQ3 {
  right: 16px;
}

._3NIIMNRvo8UUkBN8zFLXRp,
._2dgXCvHQnCQSU8ymXX5dI_ {
  color: var(--color-thumbnail-primary1);
  font-size: var(--fontSize-xs);
  position: relative;
  left: 80px;
  top: 14px;
}
._2dgXCvHQnCQSU8ymXX5dI_ {
  right: 80px;
}

._20KA-1MsMk6H6tn92FGn2o {
  text-decoration: none;
}

._1AC4i8nvpwy3GDdrsRZ9Hd {
  font-size: var(--PageAsset-title-fontSize);
  color: var(--color-thumbnail-primary1);
  font-weight: bold;
  text-shadow: 1px 1px 5px var(--color-background-asset);
  padding-top: 40px;
  padding-bottom: 10px;
}

._1_a4mYje1aLtVsHUr44bXd:after {
  content: "";
  display: table;
  clear: both;
}

._2KBdJL_oeLk9H2xvc0JwT5 {
  font-size: var(--fontSize-s);
  font-weight: normal;
}

.gnlMT2Y93z_VUAN_TEdbn {
  margin: 10px 0;
}

._2zbh4b96s2_NgpIIjzsKIT {
  display: flex;
  align-items: center;
}

.UhCCd7MRoJ3RVcrZkYn9c {
  display: flex;
  align-items: center;
}

._3jU7ZZLbDlYHbISHs3qZiL {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

._3ks0ZFmm5CAsPdvNVZ_fQC {
  margin-right: 20px;
  color: var(--color-thumbnail-highlight-hover1);
  font-size: var(--fontSize-m);
  font-weight: bold;
}

._3rtMaLXRcCNaA7_nXINCK5 {
  color: var(--color-thumbnail-highlight-hover1);
  font-size: var(--fontSize-s);
  font-weight: bold;
  padding: 5px;
  background-color: var(--color-background-asset);
}

._3dit9JwTqkyeE9_93zCkVT {
  font-size: var(--fontSize-m);
  font-weight: normal;
  color: var(--color-thumbnail-primary3);
  text-shadow: 1px 1px 5px var(--color-background-asset);
}

._1tzKIP-HVBwH3N5g8uI2xW {
  margin: 20px 0;
  display: inline-flex;
}

.zxRgIUAnv20SzeJt08UMd {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
}

.zxRgIUAnv20SzeJt08UMd::before,
.zxRgIUAnv20SzeJt08UMd::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-style: solid;
}

.zxRgIUAnv20SzeJt08UMd::before {
  border-width: 20px;
}

.zxRgIUAnv20SzeJt08UMd::after {
  border-width: 20px;
  border-right-color: var(--color-border-right-padlock-container);
  border-top-color: var(--color-border-top-padlock-container);
}

.yb0Fa1Le-JsXLIiYFbvS {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 1;
}

._334GYNo71HFFxRS69OFssP,
._334GYNo71HFFxRS69OFssP svg {
  width: 16px;
  fill: var(--color-icon-playlock-fill);
}

._3bHOImQyC0WGt46qf_pcOc:hover ._1MvnuaqwquGIauOSYAZ_hX,
._20KA-1MsMk6H6tn92FGn2o:hover ._1MvnuaqwquGIauOSYAZ_hX {
  display: none;
}

._3bHOImQyC0WGt46qf_pcOc:hover ._3dchtLpsoIu241VWsQD4P,
._20KA-1MsMk6H6tn92FGn2o:hover ._3dchtLpsoIu241VWsQD4P {
  display: initial;
}

._1MvnuaqwquGIauOSYAZ_hX,
._31OnvyFasFNjhn_7lHTrLA {
  display: initial;
}

._3dchtLpsoIu241VWsQD4P,
.L14oIwC98lFIVsIJP5zU- {
  display: none;
}

._3bHOImQyC0WGt46qf_pcOc:hover ._1MvnuaqwquGIauOSYAZ_hX,
._20KA-1MsMk6H6tn92FGn2o:hover ._31OnvyFasFNjhn_7lHTrLA {
  display: none;
}

._3bHOImQyC0WGt46qf_pcOc:hover ._3dchtLpsoIu241VWsQD4P,
._20KA-1MsMk6H6tn92FGn2o:hover .L14oIwC98lFIVsIJP5zU- {
  display: initial;
}

@media (max-width: 1023px) {
  ._1qyMpahUl3MC477Gg8WfP2 {
    font-size: var(--fontSize-xs);
  }
}

@media (max-width: 767px) {
  :root {
    --PageAsset-headerHeigth: 401px;
  }

  ._1_a4mYje1aLtVsHUr44bXd {
    width: 100%;
  }

  ._1AC4i8nvpwy3GDdrsRZ9Hd {
    font-size: var(--fontSize-l);
  }

  ._2KBdJL_oeLk9H2xvc0JwT5,
  ._3ks0ZFmm5CAsPdvNVZ_fQC,
  ._3dit9JwTqkyeE9_93zCkVT {
    font-size: var(--fontSize-s);
  }

  ._3jU7ZZLbDlYHbISHs3qZiL {
    width: 40px;
    height: 40px;
  }

  ._1tzKIP-HVBwH3N5g8uI2xW {
    display: flex;
    justify-content: space-around;
  }

  ._21oEDdpLargl7fqAuW2-tr svg,
  ._3r9kOiSY8FzWx9ZyqqZsXa svg {
    width: 40px;
    height: 40px;
  }

  ._21oEDdpLargl7fqAuW2-tr,
  ._3r9kOiSY8FzWx9ZyqqZsXa {
    position: absolute;
    left: calc(50% - (40px / 2));
    top: calc(50% - (40px / 2));
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  ._31OnvyFasFNjhn_7lHTrLA,
  .L14oIwC98lFIVsIJP5zU- {
    top: 0;
  }

  ._3btChQ4uwzjpFFPSmBSuWh {
    margin-bottom: -20px;
    position: absolute;
    top: -13px;
    left: -25px;
    width: 130px;
  }

  ._3zBHV-7cNNTIPMHKj6stGl {
    width: calc(100% - 40px);
  }

  .ZBYi1T3zTCGATm6qpz1fv {
    background-color: var(--color-background-asset);
    width: 130px;
    height: 24px;
    line-height: 24px;
    color: var(--color-content-default1);
    font-size: var(--fontSize-xs);
    text-transform: uppercase;
  }

  ._30ntXaOYnWQcTA6j5ylpfa {
    left: 110px;
  }

  ._2YlXYbzsEa5wayxUxX4Mmu {
    background-color: var(--color-background-asset);
    left: -25px;
    top: -10px;
    width: 130px;
    height: 24px;
    line-height: 24px;
    font-size: var(--fontSize-xs);
  }

  ._3NIIMNRvo8UUkBN8zFLXRp {
    left: 100px;
    top: 0;
  }

  ._1C6yQCU-HqLybrLhl2V_GB {
    left: 115px;
    top: 20px;
  }

  ._20KA-1MsMk6H6tn92FGn2o:hover ._31OnvyFasFNjhn_7lHTrLA,
  ._20KA-1MsMk6H6tn92FGn2o:hover ._1MvnuaqwquGIauOSYAZ_hX {
    display: none;
  }

  ._20KA-1MsMk6H6tn92FGn2o:hover .L14oIwC98lFIVsIJP5zU-,
  ._20KA-1MsMk6H6tn92FGn2o:hover ._3dchtLpsoIu241VWsQD4P {
    display: initial;
  }
}

._2m8H_P8bwzn2Z2ILQPFZ9z {
  width: 90%;
  display: flex;
  position: absolute;
  top: -25px;
  align-items: center;
}

._3myDDMuexiQm96OIeQlIK-,
._3myDDMuexiQm96OIeQlIK- svg {
  width: 56px;
  height: 56px;
  fill: var(--color-icon-playlock-fill);
  stroke: var(--color-icon-playlock-stroke);
}

._3myDDMuexiQm96OIeQlIK- {
  cursor: pointer;
  left: calc(50% - (56px / 2));
  top: calc(50% - (56px / 2));
  width: 56px;
  height: 56px;
  /* fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth); */
}

.CJE_M9maum87LpBKJPXyT {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 13px;
}

.CJE_M9maum87LpBKJPXyT p {
  margin-top: unset;
}

._3myDDMuexiQm96OIeQlIK-:hover > svg {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}

._3myDDMuexiQm96OIeQlIK- circle {
  fill: var(--color-icon-playlock-circle-fill);
  fill-opacity: var(--color-icon-playlock-circle-fillOpacity);
  stroke-width: var(--color-icon-playlock-circle-strokeWidth);
}

._3myDDMuexiQm96OIeQlIK-:hover {
  fill: var(--color-icon-playlock-fill-hover);
  stroke: var(--color-icon-playlock-stroke-hover);
}
