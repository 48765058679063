html,
body {
  min-height: 100vh;
}
html {
  background: var(--color-background);
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
  /* beemix-font is defined in skin…
   * If no define we fall back on default OS font
   */
  font-family: beemix-font, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar-track);
  background-image: var(--color-background);
  background-repeat: no-repeat;
}

body::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--color-scrollbar-track);
}

body::-webkit-scrollbar {
  width: 10px;
}

div::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

body::-webkit-scrollbar-corner,
div::-webkit-scrollbar-corner {
  background: var(--color-scrollbar-corner);
}

body::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  border-radius: var(--borderRadius-scrollbar-track);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--color-scrollbar-thumb);
}

body::-webkit-scrollbar-thumb:hover,
div::-webkit-scrollbar-thumb:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--color-scrollbar-thumb-hover);
}

#root {
  overflow: hidden;
  min-height: 100vh;
}
/* Force how subtitles from Veygo Player are displayed */
.veygoSubsDisplayer {
  bottom: 4rem;
}
@media only screen and (min-width: 992px) {
  .veygoSubsDisplayer {
    top: unset !important;
  }
}
.veygoSubsDisplayer div {
  top: var(--subtitle-vertical-position) !important;
}
.veygoSubsDisplayer div span {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

.veygo-video {
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
}
